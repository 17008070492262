// Here you can add other styles
.ka-table-light-theme .ka-thead-background{
  z-index: auto !important;
}

.ka-table-dark-theme .ka-thead-background{
  z-index: auto !important;
}

.primary {
  color: var(--cui-primary);
}

.pointer{
  cursor: pointer;
}

.input-group span.input-group-text {
  border: var(--cui-border-width) solid var(--cui-border-color);
  border-top-right-radius: var(--cui-border-radius) !important;
  border-bottom-right-radius: var(--cui-border-radius) !important;
}

.text-left {
  text-align: left !important;
}

.noBackground .ka-table-light-theme .ka-thead-background {
  background-color: transparent !important;
}

.noBackground .ka-table-dark-theme .ka-thead-background {
  background-color: transparent !important;
}

.ka-table-light-theme .ka {
  background-color: transparent !important;
}

.ka-table-dark-theme .ka {
  background-color: transparent !important;
}

input[type="checkbox"] {
  cursor: pointer;
}

.errorMessage{
  display:  block !important;
}

* {
  --cui-sidebar-occupy-start: 13rem !important;
  --cui-sidebar-width: 13rem !important;
}

[data-coreui-theme=dark] .badge.bg-dark{
  color: black !important;
  background-color: white !important;
}

.btn.btn-danger{
  color: white !important;
}

// invoice
.summary-table .ka-group-summary-row {
  position: relative !important;
}


// .css-mbg7gq-control {
//   -webkit-align-items: center;
//   -webkit-box-align: center;
//   -ms-flex-align: center;
//   align-items: center;
//   cursor: default;
//   display: -webkit-box;
//   display: -webkit-flex;
//   display: -ms-flexbox;
//   display: flex;
//   -webkit-box-flex-wrap: wrap;
//   -webkit-flex-wrap: wrap;
//   -ms-flex-wrap: wrap;
//   flex-wrap: wrap;
//   -webkit-box-pack: justify;
//   -webkit-justify-content: space-between;
//   justify-content: space-between;
//   min-height: 38px;
//   outline: 0 !important;
//   position: relative;
//   -webkit-transition: all 100ms;
//   transition: all 100ms;
//   background-color: #333;
//   border-color: white;
//   border-radius: 4px;
//   border-style: solid;
//   border-width: 1px;
//   box-sizing: border-box;
// }
